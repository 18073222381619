import React, { useEffect, useRef } from "react";
import ChatMessage from "./ChatMessage";
import LoaderSpinner from "./LoaderSpinner";

const ChatBox = ({ messages, onSubmitForm, loading }) => {
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, loading]);

  return (
    <div style={styles.chatBox}>
      {messages.map((message, index) => (
        <ChatMessage
          key={index}
          message={message}
          onSubmitForm={onSubmitForm}
        />
      ))}

      {loading && (
        <div style={styles.spinnerContainer}>
          <LoaderSpinner />
        </div>
      )}

      <div ref={chatEndRef} />
    </div>
  );
};

const styles = {
  chatBox: {
    height: "75vh",
    overflowY: "scroll",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "20px",
    backgroundColor: "#303030",
    position: "relative",
    margin: "10px",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "60px",
    marginTop: "10px",
  },
};

export default ChatBox;
