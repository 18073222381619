import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";

const MessageInput = ({ onSend }) => {
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSend(message); // Appel à la fonction onSend pour envoyer le message
    setMessage("");
  };

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      <input
        type="text"
        value={message}
        onChange={handleChange}
        placeholder="Envoyer un message..."
        style={styles.input}
      />
      <button type="submit" style={styles.button}>
        <SendIcon />
      </button>
    </form>
  );
};

const styles = {
  form: {
    display: "flex",
    padding: "10px",
    borderTop: "1px solid #ccc",
  },
  input: {
    flex: 1,
    padding: "10px",
    fontSize: "16px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#FF0000",
    border: "1px solid #FF0000",
    color: "#fff",
    borderRadius: "10px",
  },
};

export default MessageInput;
