import React, { useEffect, useState } from "react";
import MarkdownIt from "markdown-it";
import Linkify from "react-linkify";
import DynamicForm from "./DynamicForm";
import userProfile from "../assets/Placeholder.png"; // Placeholder avatars
import aiProfile from "../assets/avatar.png";

const md = new MarkdownIt();

const ChatMessage = ({ message, onSubmitForm }) => {
  const [renderedMessage, setRenderedMessage] = useState("");
  const [parsedForm, setParsedForm] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const renderMarkdown = () => {
      const renderedMsg = md.render(message.text);
      setRenderedMessage(renderedMsg);

      // Vérification de l'existence d'un formulaire
      if (message.form && !isFormSubmitted) {
        try {
          const parsedForm = JSON.parse(message.form);
          setParsedForm(parsedForm);
        } catch (error) {
          console.error("Error parsing form JSON:", error);
        }
      }
    };

    renderMarkdown();
  }, [message.text, message.form, isFormSubmitted]);

  const handleSubmitForm = (formData) => {
    onSubmitForm(formData);
    setParsedForm(null); // Supprimer le formulaire après soumission
    setIsFormSubmitted(true); // Marquer le formulaire comme soumis
  };

  return (
    <div
      style={{
        ...styles.messageContainer,
        flexDirection: message.sender === "user" ? "row-reverse" : "row",
      }}
    >
      <img
        src={message.sender === "user" ? userProfile : aiProfile}
        alt={`${message.sender} profile`}
        style={styles.profileImage}
      />
      <div style={{ textAlign: message.sender === "user" ? "right" : "left" }}>
        <Linkify>
          <div
            style={{
              ...styles.message,
              backgroundColor:
                message.sender === "user" ? "#007BFF" : "#f1f1f1",
              color: message.sender === "user" ? "#fff" : "#000",
            }}
            dangerouslySetInnerHTML={{ __html: renderedMessage }}
          ></div>
        </Linkify>

        {/* Affiche le formulaire si le formulaire n'a pas été soumis */}
        {parsedForm && !isFormSubmitted && (
          <div style={{ ...styles.message, backgroundColor: "#f1f1f1" }}>
            <DynamicForm form={parsedForm} onSubmit={handleSubmitForm} />
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  messageContainer: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "10px",
  },
  profileImage: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    margin: "0 10px",
  },
  message: {
    display: "inline-block",
    padding: "10px",
    borderRadius: "10px",
    maxWidth: "100%",
    whiteSpace: "pre-wrap",
  },
};

export default ChatMessage;
