// DynamicForm.js
import React, { useState } from "react";

const DynamicForm = ({ form, onSubmit }) => {
  const [formData, setFormData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false); // État pour contrôler l'affichage

  if (!form || typeof form !== "object") return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const renderField = (fieldKey, field) => {
    return (
      <div key={fieldKey} style={styles.fieldContainer}>
        <label style={styles.label}>{field.description}</label>
        {field.type === "text" && (
          <input type="text" name={fieldKey} onChange={handleChange} style={styles.input} />
        )}
        {field.type === "dropdown" && (
          <select name={fieldKey} onChange={handleChange} style={styles.input}>
            {field.values &&
              field.values.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
          </select>
        )}
      </div>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Appelle la fonction onSubmit pour envoyer les données
    setIsSubmitted(true); // Masquer le formulaire après envoi
  };

  // Si le formulaire a été soumis, on ne l'affiche plus
  if (isSubmitted) {
    return <p>Formulaire envoyé avec succès !</p>;
  }

  return (
    <form onSubmit={handleSubmit} style={styles.form}>
      {Object.keys(form).map((fieldKey) => renderField(fieldKey, form[fieldKey]))}
      <button type="submit" style={styles.submitButton}>
        Envoyer
      </button>
    </form>
  );
};

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "15px",
    backgroundColor: "#f9f9f9",
    border: "1px solid #ccc",
    borderRadius: "8px",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "5px",
    fontWeight: "bold",
  },
  input: {
    padding: "8px",
    fontSize: "14px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  submitButton: {
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default DynamicForm;
