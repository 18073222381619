// LoaderSpinner.js
import React from "react";
import Spinner from "react-bootstrap/Spinner";

const LoaderSpinner = () => {
  return (
    <div style={spinnerStyle}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Chargement...</span>
      </Spinner>
    </div>
  );
};

const spinnerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100px", // Vous pouvez ajuster selon vos besoins
};

export default LoaderSpinner;
