import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatBox from "./components/ChatBox";
import MessageInput from "./components/MessageInput";
import { initContextIA, sendMessage } from "./redux/actions/iaActions";
import NavigationBar from "./components/NavigationBar";
import Container from "react-bootstrap/Container";

const App = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.ia.messages);
  const userSession = useSelector((state) => state.ia.userSession);
  const loading = useSelector((state) => state.ia.loading);

  useEffect(() => {
    dispatch(
      initContextIA({
        versionGPT: "gpt-4-0125-preview",
        products: [
          "Pergolas",
          "Stores d'intérieur",
          "Stores d'extérieur",
          "Carports",
          "Fenêtres",
          "Portes d'entrées",
          "Portes de garage",
          "Clôtures et portails",
          "Autre",
        ],
      })
    );
  }, [dispatch]);

  const handleSendMessage = (message) => {
    if (message.trim() && userSession) {
      dispatch(sendMessage(message, userSession));
    }
  };

  const handleSubmitForm = (formData) => {
    const formMessage = Object.entries(formData)
      .map(([key, value]) => `${key}: ${value}`)
      .join("\n");
    handleSendMessage(formMessage);
  };

  return (
    <div>
      <NavigationBar />
      <Container className="mt-1">
        <h1 className="d-flex justify-content-center align-items-center storeferm mt-2">
          {" "}
          Bienvenue sur le chatbot de Storeferm
        </h1>
        <p className="d-flex justify-content-center align-items-center mb-5">
          Vous pouvez lui poser toutes les questions que vous voulez à propos de
          nos produits. Essayez par vous-même , dîtes lui bonjour !
        </p>
      </Container>
      <Container fluid>
        <ChatBox
          messages={messages}
          onSubmitForm={handleSubmitForm}
          loading={loading}
        />
        <MessageInput onSend={handleSendMessage} />
        <div className="d-flex justify-content-center align-items-center mt-5 ">
          <a
            target="_blank"
            rel="noreferrer"
            className="contact"
            href="https://storeferm.fr/devis-gratuit"
          >
            Nous contacter
          </a>
        </div>
      </Container>
    </div>
  );
};

export default App;
